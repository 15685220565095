.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.loginForm {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.loginForm h2 {
  margin-bottom: 1rem;
  color: #333;
}

.inputGroup {
  margin-bottom: 1rem;
}

.inputGroup label {
  display: block;
  margin-bottom: 0.5rem;
  color: #555;
}

.inputGroup input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.loginButton {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.loginButton:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .loginForm {
    padding: 1rem;
  }
}