section#admin {
  display: flex;
  gap: 8px;
  align-items: center;
  background: #c7d2e2;
  width: 100vw;
  height: 100vh;
  padding: 8px;
}

header#header {
  position: fixed;
  height: 100%;
  width: 260px;
  background: #fefefe;
  position: relative;
  border-radius: 23px;
  /* box-shadow: 0 0 10px #00000020; */
}

div.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  height: 72px;
  width: 85%;
  border-bottom: 1px solid #bababa;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  img {
    height: 100%;
  }
}

nav#nav {
  height: 100%;
  max-height: calc(100vh - 250px);
  padding: 20px 0;
  width: 85%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  align-items: center;
  position: relative;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
}

nav#nav a {
  text-decoration: none;
  font-size: .8rem;
  border-radius: 8px;
  width: 100%;
  padding: 12px 14px 12px 18px;
  color: #244b8d;
  font-family: Quicksand;
  font-weight: bold;
  transition: 200ms;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
}

nav#nav a.active {
  background: #ecf1f9;
  /* border-radius: 0 8px 8px 0; */

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 5px;
    left: 0;
    top: 0;
    background: #244b8d;
  }
}

nav#nav a:hover {
  background: #ecf1f9;
}

section#content {
  /* position: absolute;
  left: 230.5px; */
  height: 100%;
  width: 100%;
  /* width: calc(100vw - 230px); */
  background: #fefefe;
  border-radius: 23px;
  overflow: auto;
  /* box-shadow: 0 0 10px #00000020; */
}

@media screen and (max-width: 768px) {
  section#admin {
    display: flex;
    flex-direction: column;
  }

  header#header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    height: 60px;
    width: calc(100vw - 16px);
    background: #fefefe;
    position: relative;
    border-radius: 15px;
    /* box-shadow: 0 0 10px #00000020; */
  }

  div.logo {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding: 15px 0;
    height: 56px;
    width: fit-content;
    border-bottom: 1px solid #bababa;
    position: relative;
    left: 20px !important;
    transform: none !important;

    img {
      height: 100%;
    }
  }

  nav#nav {
    height: fit-content;
    max-height: fit-content;
    padding: 0 15px 0 5px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
    position: relative;
    top: 0 !important;
    left: 0px !important;
    transform: none !important;
  }

  nav#nav a {
    text-decoration: none;
    font-size: .65rem !important;
    border-radius: 3px;
    width: fit-content;
    max-width: 70px !important;
    padding: 6px !important;
    color: #244b8d;
    font-family: Quicksand;
    font-weight: bold;
    transition: 200ms;
    cursor: pointer;
    position: relative;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  nav#nav a.active {
    background: none;
    /* border-radius: 0 8px 8px 0; */

    &::before {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      left: 0;
      top: 25px;
      background: #244b8d;
    }
  }
}