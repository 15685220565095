div.container {
  padding: 25px;
  width: 450px;
  height: 400px;
  background: #fff;
  font-family: AbeeZee;
  border-radius: 10px;
  position: relative;
  border-radius: 15px;
  /* border: 1px solid var(--soft-blue); */
  /* box-shadow: 0 0 5px #88b3cbd1; */
}

div.inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 40px;
  position: relative;
  top: 30px;
}

div.shows-request label,
div.shows-message label {
  position: relative;
  display: flex;
}

div.shows-request label span,
div.shows-message label span {
  position: absolute;
  left: 8px;
  top: 7px;
  font-size: 12px;
  transition: 150ms;
  color: var(--soft-blue);
}

div.shows-message {
  height: calc(100% - 200px);
  width: 100%;
}

div.shows-request {
  width: 100%;
}

div.shows-message label {
  width: 100%;
  height: fit-content;
}

div.shows-request label input#shows-request-input,
div.shows-message label textarea#shows-message-input {
  border: none;
  border-bottom: 1px solid var(--soft-blue);
  outline: none;
  padding: 5px 8px;
  height: 30px;
  width: 100%;
}

input#shows-request-input:focus ~ span,
textarea#shows-message-input:focus ~ span,
input#shows-request-input:not(:placeholder-shown) ~ span,
textarea#shows-message-input:not(:placeholder-shown) ~ span {
  top: -20px;
  left: 2px;
  font-size: 10px;
  color: var(--soft-blue);
}

div.shows-message label textarea {
  max-height: 150px;
  min-height: 30px;
  width: 100%;
  min-width: 70%;
  max-width: 100%;
  font-family: abeezee;
}

div.buttons {
  position: absolute;
  right: 25px;
  bottom: 23px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

div.buttons a {
  border: none;
  outline: none;
  color: white;
  background: var(--soft-blue);
  padding: 10px 22px;
  border-radius: 5px;
  font-size: 12px;
  text-decoration: none;
  transition: 150ms;
}

div.buttons a:hover {
  filter: saturate(.7);
  transform: scale(.9);
}