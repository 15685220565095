section#contact {
  position: relative;
  top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
}

div.banner {
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url(../../../public/Imgs/about-desc2.jpg);
  padding: 60px 15px;
  background-position: center calc(50% + 20px);
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
}

.banner > * {
  text-align: center;
  font-family: Poppins;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.332);
}

div.social {
  display: flex;
  justify-content: center;
  gap: 20px;
  position: relative;
  top: 15px;

  a {
    text-decoration: none;
    color: white;
    font-size: 1.1em;
    transition: 150ms;

    &:hover {
      transform: scale(1.5);

      &:nth-child(1) {
        color: #63a1fe;
      }

      &:nth-child(2) {
        color: #ff47a3;
      }

      &:nth-child(3) {
        color: #ff47a3;
        filter: drop-shadow(-1px 1px 0 #1667e0);
      }
    }
  }
}

.title {
  font-size: 40px;
  color: white;
  /* font-weight: bold; */
}

.desc {
  color: #eaeaea;
  /* font-weight: bold; */
}

div.info {
  display: flex;
  gap: 10px;
  position: relative;
  /* top: 55px; */
  line-height: 170%;
  padding: 35px 30px;
  font-size: 98%;
  align-items: center;
  background: var(--bg-blue);
  border-radius: 20px;
  border: 1px solid #b8d2fb;

  > div:not(.panel) {
    flex: 1;
    box-shadow: -4px 4px 10px #00000025;
  }

  div.panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* background: #000; */
    height: 400px;
    width: fit-content;
    flex: .8;

    h1 {
      width: 60%;
      min-width: 400px;
      /* background: #000; */
      white-space: wrap;
      color: #111;
      font-family: Poppins;
      line-height: 1.5;
      font-size: 1.7em;
      /* font-weight: bold; */

      span {
        color: var(--primary-blue);
        font-family: Poppins;
      }
    }

    div.social-attrs {
      display: flex;
      flex-direction: column;
      gap: 20px;

      div.attr {
        display: flex;
        gap: 15px;
        align-items: center;

        p {
          color: #222;
        }

        svg {
          color: var(--primary-blue);
        }
      }
    }
  }
}

div.img {
  background: url(../../../public/Imgs/about-desc.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 124px 163px 90px 79px;
  transition: 200ms;
  height: 110%;
  animation: imgAnimation infinite 10s forwards linear;
}

@keyframes imgAnimation {
  0% {
    background-image: url(../../../public/Imgs/about-desc.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 124px 163px 90px 79px;
  }

  30% {
    opacity: 1;
    background: url(../../../public/Imgs/about-desc2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 174px 173px 190px 120px;
  }

  60% {
    background-image: url(../../../public/Imgs/about-desc.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

div.reviews {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  padding: 2px 22px;
  overflow-y: hidden;
  overflow-x: auto;
  position: relative;
  top: 35px;
  gap: 25px;
  height: 100%;

  h1 {
    position: relative;
    /* left: 30px; */
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    color: #111;
  }

  > div {
    display: flex;
    gap: 30px;
    overflow-y: hidden;
    align-items: stretch;
    height: 100%;
    /* width: auto; */
    overflow-x: scroll;
    padding-bottom: 20px;
    /* flex-wrap: nowrap; */
    /* white-space: nowrap; */

    a {
      text-decoration: none;
      display: block;
    }
  }
}

.review {
  width: 350px;
  height: 100%;
  padding: 20px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.review p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  color: #212121;

  &.name {
    max-width: 80%;
    font-size: 1.4em;
    color: #121212;
    font-weight: bold;
  }

  &.msg {
    position: relative;
    top: 10px;
  }
}

@media screen and (max-width: 768px) {
  div.img {
    display: none;
  }

  div.info {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 10px);
    padding: 15px 8px !important;
    gap: 30px !important;

    div.panel {
      gap: 30px !important;
    }

    > div:not(.panel) {
      width: 100%;
      height: 400px;
      flex: auto;
    }

    h1 {
      text-align: center !important;
      width: 40% !important;
      min-width: fit-content !important;
      font-size: 1.3em !important;
    }

    p {
      text-align: justify;
    }
  }

  .reviews {

    width: calc(100% - 50px) !important;
    padding: 2px 10px !important;

    h1 {
      font-size: 1.6em !important;
      text-align: center;
    }
  }

  .review {
    width: 80vw !important;
  }
}