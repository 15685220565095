.footer {
  background-color: var(--primary-blue);
  color: white;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 100px;
  margin-top: 350px;
}

.logoContainer {
  margin-bottom: 25px;
}

.logo {
  width: 100px;
  border-radius: 100px;
}

.links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.links li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.links li a:hover {
  color: aqua;
}

.copyright {
  font-size: 14px;
  margin-top: 5px;
}

.poweredBy {
  font-size: 14px;
  margin-top: 10px;
  position: relative;
  top: 10px;
  margin-bottom: 10px;

  a {
    color: aqua;
  }
}

@media screen and (max-width: 768px) {

  .footer {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: center;
    padding: 25px 30px;
    height: fit-content;
    min-height: 200px;
  }

  .links {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    align-items: center;
    /* justify-content: center !important; */

    * {
      text-align: center !important;
      font-size: 12px;
    }
  }

  .logoContainer {
    position: relative;
    top: -15px;
  }

  p.copyright {
    position: relative;
    font-size: small;
    text-align: center !important;
    width: 100%;
    top: 10px;
  }

  p.poweredBy {
    position: relative !important;
    font-size: small;
    text-align: center !important;
    width: 100%;
    /* transform: translateX(-50%); */
    /* left: 50%; */
    top: 20px !important;
    margin-bottom: 0 !important;
    border-top: 1px solid #444;
    padding: 3px 0;

    a {
      color: aqua;
    }

    /* top: 100%; */
  }
}