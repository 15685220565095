div.options-bar {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* width: 55%; */
  height: 2.3rem;
  /* padding: 12px; */
  background: #050505;
  border-radius: 50px;
  position: fixed;
  /* left: calc(50% + 57px);
  transform: translateX(calc(-50% + 57px)); */
  bottom: 40px;
  overflow: hidden;
}

div.options-bar > div.optionOpenClose > p {
  color: #fefefe;
  transition: 140ms;
  cursor: pointer;
}

div.options-bar > div.optionOpenClose {
  transition: 140ms;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.3rem;
  width: 2.3rem;
  border-radius: 50%;
  z-index: 3;
}

div.options-bar > div.optionOpenClose.opened {
  position: absolute;
  left: calc(100% - 50px);
  transform: translateX(0);
}

div.options-bar.opened div.major-options {
  display: flex;
  position: relative;
  left: 4px;
  gap: 8px;
  align-items: center;
  z-index: 1;
  opacity: 1;
  transition: 400ms;
}

div.options-bar div.major-options {
  transition: 150ms;
  opacity: 0;
}

div.major-options > button {
  border: none;
  outline: none;
  padding: 8px 11px;
  font-size: 12px;
  border-radius: 20px;
  width: fit-content;
  /* background: #fefefe; */
  background: none;
  /* color: #121212; */
  color: #fefefe;
  transition: 120ms;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  div.major-options > button {
    padding: 2px 5px !important;
    font-size: 11px !important;
    border-radius: 10px !important;
  }
}