div.filters-part {
  display: flex;
  flex-direction: column;
  gap: 25px;
  /* justify-content: center; */
  /* align-items: center; */
  font-family: Galano;
  font-size: 20px;

  div.filters-container {
    display: flex;
    height: calc(100vh - 25px);
    overflow-y: auto;
    padding: 0 7px;
    position: sticky;
    top: 100px;
    flex-direction: column;
    /* max-height: 0; */
    transition: 150ms;
    /* gap: 20px; */
  }
}

div.filters-part > p {
  font-size: 20px;
  margin-bottom: 0;
  margin-left: 15px;
  margin-top: 25px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    position: relative;
    right: 10px;
    color: #555;
    display: none;
  }
}

div.some-filter {
  /* background: #888; */
  position: relative;
  display: flex;
  flex-direction: column;

  &.open > .some-container {
    max-height: 180px;
  }

  &.open > div.title > p.indicator {
    transform: rotateZ(90deg);
  }

  > div.title {
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    cursor: pointer;
    display: flex;
    font-family: Galano, sans-serif;
    font-size: 15px;
    height: 50px;
    justify-content: space-between;
    text-transform: uppercase;

    p.indicator {
      font-size: 1em;
      transition: 150ms;
      color: #aaa;
    }
  }

  > div.some-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-height: 0;
    overflow: auto;
    transition: .2s ease-in-out;

    > div:is(.brand, .fuel, .gearbox) {
      display: flex;
      align-items: center;
      padding: 5px;
      justify-content: space-between;

      p:is(.brand-name, .fuel-type, .gearbox-type) {
        font-size: .75em;
        width: 100%;
      }

      input {
        accent-color: var(--soft-blue);
      }
    }

    > div:is(.prices, .mileage-range, .year-range) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 5px;

      > p {
        font-size: .8em;
        color: #555;
      }

      > input {
        border: none;
        border-bottom: 1px solid var(--soft-blue);
        outline: none;
        position: relative;
      }

      > input::after {
        content: "km";
        position: absolute;
        right: 20px;
        bottom: 5px;
        height: 10px;
        width: 10px;
        background: #000;
        color: #555;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  div.filters-part {
    display: flex;
    flex-direction: column;
    gap: 25px;
    font-family: Galano;
    font-size: 20px;

    > p span {
      display: block;
    }

    div.filters-container {
      display: flex;
      height: fit-content !important;
      overflow-y: auto;
      padding: 0 7px;
      position: sticky;
      top: 25px;
      flex-direction: column;
      max-height: 0;
      opacity: 0;
      transition: all 150ms;

      &.open {
        max-height: 500px;
        opacity: 1;
      }

      /* gap: 20px; */
    }
  }

  div.some-filter {
    /* background: #888; */
    position: relative;
    display: flex;
    flex-direction: column;

    &.open > .some-container {
      max-height: 130px;
    }

    &.open > div.title > p.indicator {
      transform: rotateZ(90deg);
    }

    > div.title {
      align-items: center;
      border-bottom: 1px solid #f5f5f5;
      cursor: pointer;
      display: flex;
      font-family: Galano, sans-serif;
      font-size: 15px;
      height: 50px;
      justify-content: space-between;
      text-transform: uppercase;

      p.indicator {
        font-size: 1.2em;
        transition: 150ms;
      }
    }

    > div.some-container {
      display: flex;
      flex-direction: column;
      gap: 6px;
      max-height: 0;
      overflow: auto;
      transition: .2s ease-in-out;
      /* background: #888; */

      > div:is(.brand, .fuel, .gearbox) {
        display: flex;
        align-items: center;
        padding: 5px;
        justify-content: space-between;

        p:is(.brand-name, .fuel-type, .gearbox-type) {
          font-size: .75em;
          width: 100%;
        }

        input {
          accent-color: var(--soft-blue);
        }
      }

      > div:is(.prices, .mileage-range, .year-range) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 5px;
        /* background: #000; */

        > p {
          font-size: .8em;
          color: #555;
        }

        > input {
          border: none;
          border-bottom: 1px solid var(--soft-blue);
          outline: none;
        }
      }
    }
  }

}