section#vtc {
  position: relative;
  top: 80px;
  display: flex;
  flex-direction: column;
}

div.hero {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.45)), url(../../../public/Imgs/carbg.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px 30px;
  /* justify-content: center; */
  gap: 5px;

  h1 {
    font-size: 3em;
    color: #fefefe !important;
    position: relative;
    text-align: center;
    top: -5px;
  }

  p {
    font-size: 1.1em;
    color: white !important;
    position: relative;
    top: -2px;
  }

  a,
  button {
    display: block;
    text-decoration: none !important;
    white-space: nowrap;
    border: none;
    border-radius: 30px;
    outline: none;
    font-size: 13.5px;
    padding: 10px 20px;
    width: 195px;
    background: #ddd !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: #111;
    position: relative;
    top: 10px;
    cursor: pointer;
    text-align: left;
    transition: 150ms;
    overflow: hidden;

    &::after {
      position: absolute;
      white-space: nowrap;
      display: block;
      right: -30px;
      content: "\2192";
      top: 50%;
      transform: translateY(-50%);
      transition: 150ms;
      /* width: 10px; */
      /* height: 10px; */
    }

    &:hover {
      width: 220px;

      &::after {
        right: 16px;
      }
    }
  }
}

div.rates {
  /* background: #777; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 25px;
  width: 100%;

  h1 {
    font-size: 2em;
  }

  div.rate {
    display: flex !important;
    width: 80%;
    justify-content: space-between !important;
    padding: 10px 20px;
    border-radius: 8px;
    border: 1px solid #bbb;
    align-items: center !important;
    transition: 150ms;
    position: relative;
    overflow: hidden;
    /* background: #444; */

    p.title {
      font-size: 1.3em;
      transition: 150ms;
    }

    p.price {
      font-size: 1.24em;
      position: absolute;
      right: 30px;
      transition: 170ms;
    }

    a {
      position: absolute;
      text-decoration: none;
      font-size: 13.2px;
      right: 15px;
      transform: translateX(calc(100% + 50px));
      border-radius: 40px;
      border: none;
      outline: none;
      background-color: var(--primary-blue);
      padding: 8px 18px;
      color: white;
      transition: 300ms;
      cursor: pointer;

      &:hover {
        padding: 8px 24px;
      }
    }

    &:hover {

      p.price {
        transform: translateX(-110px);
      }

      a {
        transform: translateX(0);
      }
    }
  }
}

div.note {
  display: flex;
  gap: 10px;
  position: relative;
  align-items: center;
  justify-content: center;
  top: 30px;
  padding: 0 10px 30px 10px;

  a {
    display: block;
    text-decoration: none !important;
    border-radius: 20px;
    padding: 5px 15px;
    color: white;
    background: green;
  }
}

@media screen and (max-width: 768px) {
  div.hero {
    h1 {
      font-size: 2em !important;
      position: relative;
      top: -15px !important;
    }

    p {
      font-size: .9em !important;
      position: relative;
      top: -5px;
      text-align: center;
    }
  }

  div.rate {
    width: 98% !important;

    p.title {
      font-size: .9em !important;
      max-width: 70% !important;
      white-space: wrap;
    }

    p.price {
      font-size: 1em !important;
    }

    a {
      padding: 6px 14px !important;
    }

    &:hover {
      p.title {
        opacity: .8;
        width: 30%;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap;
      }

      p.price {
        transform: translateX(-100px) !important;
      }

      a {
        transform: translateX(0);
      }
    }
  }

  div.note {
    display: flex !important;
    flex-direction: column !important;

    p {
      text-align: center !important;
    }
  }
}