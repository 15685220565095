div.dropdown-container {
  height: 100%;
  position: relative;
  width: fit-content;
  /* max-width: 200px; */
  /* width: max(100px, 100%); */
  display: flex;
  align-items: center;
  gap: 16px;
}

div.dropdown-container * {
  user-select: none;
}

div.dropdown-container p.label {
  font-size: .84rem;
  color: #161616;
  font-family: AbeeZee;
}

div.select {
  height: 30px;
  width: fit-content;
  max-width: 115px;
  text-align: center;
  padding: 2px 30px 2px 10px;
  /* border: 1px solid #cdcdcd; */
  border-radius: 3px;
  outline: none;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  color: #025883;
  position: relative;
  transition: 130ms;
  background-color: #e6f2ff;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%23025883' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 3px);
  background-position-y: 50%;

}

div.select:hover {
  transform: scale(1.03);
}

p.default-item {
  font-size: .82rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  position: relative;
  left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.other-items {
  position: absolute;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.25));
  width: 140px;
  top: 40px;
  max-height: 180px;
  /* gap: 1px; */
  transform-origin: top;
  z-index: 20;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 240px;
  border-radius: 10px;

}

div.other-items button {
  flex-shrink: 0;
  text-decoration: none;
  outline: none;
  border: none;
  color: #343434;
  padding: 6px 12px;
  background: #fefefe;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .776rem;
  position: relative;
  transition: 130ms;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.other-items button:not(:last-child) {
  border-bottom: .5px solid #ddd;
}

div.other-items button:hover {
  /* background: #ededed; */
  transform: scale(1.04);
  /* border: .5px solid #ddd; */
  box-shadow: 0 0 5px #bcbcbc;
  z-index: 10;
}