@font-face {
  font-family: Quicksand;
  src: url("/Fonts/Quicksand.ttf");
}

@font-face {
  font-family: Righteous;
  src: url("/Fonts/Righteous.ttf");
}

@font-face {
  font-family: Aharoni;
  src: url("/Fonts/Aharoni.ttf");
}

@font-face {
  font-family: ABeeZee;
  src: url("/Fonts/ABeeZee.ttf");
}

@font-face {
  font-family: Roboto;
  src: url("/Fonts/Roboto.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("/Fonts/Montserrat.ttf");
}

@font-face {
  font-family: Alata;
  src: url("/Fonts/Alata.ttf");
}

@font-face {
  font-family: Archivo;
  src: url("/Fonts/Archivo.ttf");
}

@font-face {
  font-family: Galano;
  src: url("/Fonts/Galano.otf");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* cursor: url("/Imgs/cursor.svg"), auto; */
  font-family: Galano;
}

/* ::placeholder {
  font-size: 0.9em;

  } */

:root {
  --primary-blue: #024BA5;
  --soft-blue: #025883;
  --bg-blue: #e9f1ff;
  --bg-light-blue: #eff5ff;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;

}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #1575a5;
  border-radius: 5px;
  min-height: 30px;
}

::selection {
  background: rgba(101, 219, 142, .2);
}

/* body.lightMode {
  background: var(--body-bg-light);
} */