div.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

div.page-content {
  padding: 5px 35px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: calc(100% - 130px);
}

div.page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  div.table {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    top: 23px;
    border-radius: 5px;
    overflow: hidden;
    overflow-y: auto;
    border: 1px solid #ddd;

    div.row {
      display: flex !important;
      align-items: center;
      position: relative;
      padding: 8px 35px;
      background: #fff;
      gap: 2px;
      border-top: 1px solid #ddd;

      /* justify-content: space-between;
      padding: 10px 30px; */
      /* background: #000; */
      p {
        /* background: #000; */
        position: relative;
        left: 10px;
        width: calc(31%);
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: .86em;
        font-family: Abeezee;
        color: #333;
      }

      p.date {
        position: absolute;
        left: calc(35%);
        width: calc(26% - 5px);
      }

      p.actions {
        position: absolute;
        left: 80%;
        width: calc(18%);
        display: flex !important;
        justify-content: center;
        gap: 5px;

        span {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          display: flex !important;
          align-items: center !important;
          justify-content: center;
          cursor: pointer;
          text-align: center;
          /* padding: 5px; */

          &.edit {
            background: #025883;
            text-align: center;
            color: white;
          }

          &.del:hover {
            background: red;
            color: white;
          }
        }
      }
    }

    div.header {
      background: none;
      font-size: 1.1em;
      background: #eee;
      border: none;

      p {
        color: #101010;
      }
    }
  }

  div.buttons {
    width: fit-content;
    position: relative;
    right: 0;

    button {
      padding: 9px 20px;
      width: fit-content;
      border: none;
      outline: none;
      background: #025883;
      color: white;
      border-radius: 7px;
      transition: 150ms;

      &:hover {
        background: #054a6c;
      }
    }

  }
}

@media screen and (max-width: 768px) {
  div.page-content {
    padding: 5px 15px;
  }

  div.container {
    padding: 16px 18px !important;
  }

  div.row {
    padding: 5px !important;

    p {
      /* background: #000; */
      position: relative;
      left: 10px;
      width: calc(25%) !important;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: .67em;
      font-family: Abeezee;
      color: #333;
    }

    p.date {
      position: absolute;
      left: calc(32%) !important;
      width: calc(40% - 5px) !important;
      font-family: Galano !important;
      font-size: .78em !important;
    }

    p.actions {
      position: absolute;
      left: 80%;
      width: calc(18%);
      display: flex !important;
      justify-content: center;
      gap: 2px !important;

      span {
        width: 19px !important;
        height: 19px !important;
        border-radius: 50%;
        display: flex !important;
        align-items: center !important;
        justify-content: center;
        cursor: pointer;
        text-align: center;
        /* padding: 5px; */

        &.edit {
          background: #025883;
          text-align: center;
          color: white;
        }

        &.del:hover {
          background: red;
          color: white;
        }
      }
    }
  }

  div.header {
    font-size: 1.02em !important;
  }
}