div.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

div.page-content {
  height: 100%;
  padding: 5px 35px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
}

div.details-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

div.details-container a {
  text-decoration: none;
}

div.container {
  padding: 16px 18px;
  /* border: 1px solid #bfbfbf; */
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: 250ms;
  /* overflow: hidden; */
}

div.details-container div:hover {
  box-shadow: 0 0 10px #00000025;
  transform: scale(1.02);
}

p.title {
  font-size: 1.3em;
  color: #111;
  font-weight: bold;
  text-transform: capitalize;
}

div.details-container div p.desc {
  font-size: .9rem;
  color: #666;
  /* font-family: Quicksand; */
}

div.recent-updates-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 60px);
  border-radius: 8px;
  gap: 35px;
  /* overflow: hidden; */
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: space-between;
  width: 100%; */
  /* background: #000; */
  /* height: calc(100% - 60px); */
  /* border-radius: 8px;
  gap: 25px;
  overflow: hidden; */
}

div.recent-updates-container > div {
  max-height: calc(80%);
  height: fit-content;
  min-height: max(140px, 40%);
  font-size: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  position: relative;

  > div.list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;

    p.no-recent-note {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    > div {
      border-radius: 8px;
      border: 1px solid #e5e5e5;
      /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
      padding: 10px 12px;
      display: flex;
      justify-content: space-between;
      /* position: relative; */

      > div.edit-del {
        /* position: relative;
        right: 10px; */
        display: flex;
        opacity: 0;
        gap: 6px;
        align-items: center;
        transition: 200ms;

        > button {
          width: 27px;
          height: 27px;
          font-size: 1.2em;
          border-radius: 50%;
          border: none;
          outline: none;
          cursor: pointer;
          transition: 150ms;

          &:not(.edit-btn):hover {
            background: red;
            color: white;
          }
        }

        > .edit-btn {
          background: #025883;
          color: white;
        }

        /* > .del-btn{
          background: #025883;
        } */
      }

      > div.content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        transition: 200ms;

        div.brand-title {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        p:is(.car-title, .truck-title, .brand-title) {
          font-size: .98em;
          font-weight: bold;
          color: #333;
          font-family: Quicksand;
        }

        p:is(.car-brand, .truck-brand) {
          font-size: .88em;
          color: #2b2b2b;
          font-family: Quicksand;
        }

        p:is(.date, .price) {
          font-size: .87em;
          color: #444;
          font-family: Quicksand;
          transition: 200ms;
          position: relative;
          white-space: nowrap;
          /* left: 50px; */
          transition: 100ms;
        }
      }

      &:hover .edit-del {
        opacity: 1;
        margin-left: 30px;
      }
    }
  }
}

::-webkit-scrollbar-thumb {
  background: #ddd;
}

@media screen and (max-width: 768px) {
  div.page-content {
    padding: 5px 10px;
  }

  /* .page-title {
    padding: 0 10px !important;
  } */

  div.details-container {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px;
  }

  div.recent-updates-container {
    display: flex !important;
    flex-wrap: nowrap !important;
    flex-direction: column !important;
  }
}