h1.page-title {
  height: 72px;
  display: flex;
  align-items: center;
  padding: 35px;
  font-family: Righteous;
  color: #111;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  h1.page-title {
    padding: 0 10px !important;
  }
}