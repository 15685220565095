div.add-brand {
  width: 40vw;
  height: fit-content;
  padding: 35px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  font-family: archivo;
  border-radius: 20px;
  gap: 35px;
}

div.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: #636363; */
}

div.header p.title {
  color: #343434;
  font-size: 21px;
  font-weight: 600;
}

div.header button.close {
  border: none;
  outline: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  background: #e9f1ff;
  color: #025883;
  cursor: pointer;
  font-size: 16px;
}

div.inputs {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: calc(100% - 50px);
}

div.inputs input {
  width: 100%;
  border: none;
  outline: none;
  padding: 4px 15px;
  font-family: abeezee;
  color: #343434;
  font-family: 13px;
  border-bottom: 1.4px solid #c7c7c7;
  border-radius: 3px;
  transition: 150ms cubic-bezier(0.4, 0.98, 0.35, 0.81);
}

div.inputs input:focus {
  transform: scale(1.06);
  border-bottom-color: #025883;
}

div.inputs input::placeholder {
  color: #acacac;
  font-size: 12px;
}

div.buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 15px;
}

div.buttons button {
  position: relative;
  left: -20px;
  padding: 11px 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 12px;
}

div.buttons button.add-button {
  background: #025883;
  color: white;
}

div.buttons button.cancel-button {
  border: 1.2px solid #025883;
  color: #025883;
  background: none;
}

@media screen and (max-width: 768px) {
  div.add-brand {
    width: 90vw !important;
  }
}