section#about {
  position: relative;
  top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
}

div.banner {
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url(../../../public/Imgs/about-bg.jpg);
  padding: 60px 15px;
  background-position: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.banner > * {
  text-align: center;
  font-family: Poppins;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.332);
}

.title {
  font-size: 40px;
  color: white;
  /* font-weight: bold; */
}

.desc {
  color: #eaeaea;
  /* font-weight: bold; */
}

div.info {
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  /* top: 55px; */
  line-height: 170%;
  padding: 0 20px;
  font-size: 98%;
  align-items: center;
}

div.img {
  background: url(../../../public/Imgs/about-desc.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 124px 163px 90px 79px;
  transition: 200ms;
  height: 110%;
  animation: imgAnimation infinite 10s forwards linear;
}

@keyframes imgAnimation {
  0% {
    background-image: url(../../../public/Imgs/about-desc.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 124px 163px 90px 79px;
  }

  30% {
    opacity: 1;
    background: url(../../../public/Imgs/about-desc2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 174px 173px 190px 120px;
  }

  60% {
    background-image: url(../../../public/Imgs/about-desc.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

div.reviews {
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  padding: 2px 22px;
  overflow-y: hidden;
  overflow-x: auto;
  position: relative;
  top: 35px;
  gap: 25px;
  height: 100%;

  h1 {
    position: relative;
    /* left: 30px; */
    text-align: center;
    font-size: 2em;
    font-weight: bold;
    color: #111;
  }

  > div {
    display: flex;
    gap: 30px;
    overflow-y: hidden;
    align-items: stretch;
    height: 100%;
    /* width: auto; */
    overflow-x: scroll;
    padding-bottom: 20px;
    /* flex-wrap: nowrap; */
    /* white-space: nowrap; */

    a {
      text-decoration: none;
      display: block;
    }
  }
}

.review {
  width: 350px;
  height: 100%;
  padding: 20px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.review p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  color: #212121;

  &.name {
    max-width: 80%;
    font-size: 1.4em;
    color: #121212;
    font-weight: bold;
  }

  &.msg {
    position: relative;
    top: 10px;
  }
}

@media screen and (max-width: 768px) {
  div.img {
    display: none;
  }

  div.info {
    display: flex;
    flex-direction: column;

    p {
      text-align: justify;
    }
  }

  .reviews {

    width: calc(100% - 50px) !important;
    padding: 2px 10px !important;

    > div {
      flex-direction: column !important;
      align-items: center !important;
      gap: 25px !important;
    }

    h1 {
      font-size: 1.6em !important;
      text-align: center;
    }
  }

  .review {
    width: 80vw !important;
  }
}