div.list {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  width: 100%;
}

@media screen and (max-width: 768px) {
  div.list {
    display: flex !important;
    flex-direction: column !important;
  }
}